<template>
  <a-spin tip="Processing..." :spinning="spinning" >

    <CAlert v-if="errorMsgs.length > 0" color="danger">
      <strong>Error: </strong>
      <ul v-for="msg in errorMsgs" :key="msg">
        <li>{{msg}}</li>
      </ul>
    </CAlert>

    <div >
      <CButton 
        color='primary'
        :block="true"
        class="btn-sm"
        @click="assign"
      >
        Assign
      </CButton>
    </div>

    <CCardBody class="p-0 mt-2">
      <div class="row">
        <div class="col">
          <autocomplete
            ref="autocomplete1"
            placeholder="Search Trainer"
            :source="findTrainersEndpoint"
            input-class="form-control"
            results-property="data"
            :results-display="autocompleteFormatDisplayTrainer"
            :request-headers="authHeaders"
            @selected="selectTrainer"
          >
          </autocomplete>
          <CListGroup class="mt-2" v-if="selectedTrainers.length > 0">
            <CListGroupItem class="p-2" color="dark">
              <strong>Selected Trainer(s)</strong>
            </CListGroupItem>
            <CListGroupItem class="p-1" v-for="d in selectedTrainers" :key="d.id">
              {{ d.name + " " + d.surname }}
              <a @click="removeTrainer(d.id)">
                <CIcon class="float-right mr-2" style="cursor: pointer;" name="cil-x"/>
              </a>
            </CListGroupItem>
          </CListGroup>
        </div>

        <div class="col">
          <autocomplete
            ref="autocomplete"
            placeholder="Search Course"
            :source="findCoursesEndpoint"
            input-class="form-control"
            results-property="data"
            :results-display="autocompleteFormatDisplayCourse"
            :request-headers="authHeaders"
            @selected="selectCourse"
          >
          </autocomplete>

          <CListGroup class="mt-2" v-if="selectedCourses.length > 0">
            <CListGroupItem class="p-2" color="dark">
              <strong>Selected Course(s)</strong>
            </CListGroupItem>
            <CListGroupItem class="p-1" v-for="d in selectedCourses" :key="d.id">
              {{ d.title }}
              <a @click="removeCourse(d.id)">
                <CIcon class="float-right mr-2" style="cursor: pointer;" name="cil-x"/>
              </a>
            </CListGroupItem>
          </CListGroup>
        </div>
      </div>
    </CCardBody>
   
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";
  import Autocomplete from 'vuejs-auto-complete';

  export default {
    name: "MassCourseTrainer",
    components: { Autocomplete },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        spinning: false,
        selectedTrainers: [],
        selectedCourses: []
      }
    },
    methods: {
      async assign(){
        let trainer_ids = this.selectedTrainers.map(a => a.id);
        let course_ids = this.selectedCourses.map(a => a.id);

        if (trainer_ids.length < 1 || course_ids.length < 1) {
          this.errorMsgs.push("Please select atleast one trainer and one course.")
          return;
        }

        var body = {trainer_ids: trainer_ids, course_ids: course_ids};
        var response = await HTTPRequest.postRequest(this.endPoint, "/api/v1/users/mass_assign_trainer_course", null, body);
        
        if (response.status == true) {
          this.$emit('sendToTrainers', response.client);
        } else {
          this.errorMsgs.push(response.status);
        }
        
      },
      findTrainersEndpoint(searchText) {
        return this.endPoint + "/api/v1/users/find_trainers?name=" + searchText;
      },
      autocompleteFormatDisplayTrainer (result) {
        return result.name + ' ' + result.surname;
      },
      selectTrainer (group) {
        let exists = this.selectedTrainers.find(d => d.id == group.selectedObject.id);
        if (!exists) {
          this.selectedTrainers.push(group.selectedObject);
        }
        this.$refs.autocomplete1.clear();
      },
      removeTrainer(trainer_id){
        let trainers = this.selectedTrainers.filter(d => d.id !== trainer_id);
        this.selectedTrainers = trainers;
      },

      findCoursesEndpoint(searchText) {
        return this.endPoint + "/api/v1/bookings/find_courses?title=" + searchText;
      },
      autocompleteFormatDisplayCourse (result) {
        return result.title;
      },
      selectCourse (group) {
        let exists = this.selectedCourses.find(d => d.id == group.selectedObject.id);
        if (!exists) {
          this.selectedCourses.push(group.selectedObject);
        }
        this.$refs.autocomplete.clear();
      },
      removeCourse(course_id){
        let courses = this.selectedCourses.filter(d => d.id !== course_id);
        this.selectedCourses = courses;
      },
    }
  };
</script>


<style scoped>

</style>