<template>
  <a-spin tip="Processing..." :spinning="spinning" >

    <CAlert v-if="errorMsgs.length > 0" color="danger">
      <strong>Error: </strong>
      <ul v-for="msg in errorMsgs" :key="msg">
        <li>{{msg}}</li>
      </ul>
    </CAlert>

    <div>
      <CButton 
        color='primary'
        :block="true"
        class="btn-sm mb-2"
        @click="sendCustomNotification"
      >
        Send
      </CButton>
    </div>

    <div>
        <CInput
            label="Heading"
            v-model="heading"
        />
    </div>


    <div>
        <CTextarea
            label="Content"
            v-model="content"
        />
    </div>

    <div>
        <a-switch @change="switchFilterChange" checked-children="All Trainers" un-checked-children="Selected Trainers" :checked="isAllTrainers"/>       
    </div>


    <CCardBody class="p-0 mt-3">
      <div class="row">
        <div class="col">
          <autocomplete
            ref="autocomplete1"
            placeholder="Search Trainer"
            :source="findTrainersEndpoint"
            input-class="form-control"
            results-property="data"
            :results-display="autocompleteFormatDisplayTrainer"
            :request-headers="authHeaders"
            @selected="selectTrainer"
            :disableInput="isSearchTrainerDisabled"
          >
          </autocomplete>
          <CListGroup class="mt-2" v-if="selectedTrainers.length > 0">
            <CListGroupItem class="p-2" color="dark">
              <strong>Selected Trainer(s)</strong>
            </CListGroupItem>
            <CListGroupItem class="p-1" v-for="d in selectedTrainers" :key="d.id">
              {{ d.name + " " + d.surname }}
              <a @click="removeTrainer(d.id)">
                <CIcon class="float-right mr-2" style="cursor: pointer;" name="cil-x"/>
              </a>
            </CListGroupItem>
          </CListGroup>
        </div>
      </div>
    </CCardBody>
   
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";
  import Autocomplete from 'vuejs-auto-complete';

  export default {
    name: "CustomTrainerNotification",
    components: { Autocomplete },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        spinning: false,
        selectedTrainers: [],
        selectedCourses: [],
        heading: "",
        content: "",
        isAllTrainers: true,
        isSearchTrainerDisabled: true
      }
    },
    methods: {
        switchFilterChange(state) {
            this.isAllTrainers = state;
            if (state==true) {
                this.selectedTrainers = []
                this.isSearchTrainerDisabled = true;
            } else {
                this.isSearchTrainerDisabled = false;
            }
        },
        async sendCustomNotification(){
            this.spinning = true;
            this.errorMsgs =[];

            let trainer_ids = this.selectedTrainers.map(a => a.id);

            if (!this.heading) {
              this.errorMsgs.push("Heading is required")
              return;
            }

            if (!this.content) {
              this.errorMsgs.push("Content is required")
              return;
            }
            console.log(trainer_ids.length, this.isAllTrainers)
            if ((this.isAllTrainers == false) && (trainer_ids.length < 1)) {
              this.errorMsgs.push("Please select atleast one trainer")
              return;
            }

            var body = {heading: this.heading, content: this.content, isAllTrainers: this.isAllTrainers, selectedTrainerIds: trainer_ids};
            var response = await HTTPRequest.postRequest(this.endPoint, "/api/v1/users/send_custom_notification_trainers", null, body);
            
            this.spinning = false;
            if (response.status == true) {
              this.$emit('sendToTrainers', response.client);
            } else {
              this.errorMsgs.push(response.status);
            }

        },
        findTrainersEndpoint(searchText) {
            return this.endPoint + "/api/v1/users/find_trainers?name=" + searchText;
        },
        autocompleteFormatDisplayTrainer (result) {
            return result.name + ' ' + result.surname;
        },
        selectTrainer (group) {
            let exists = this.selectedTrainers.find(d => d.id == group.selectedObject.id);
            if (!exists) {
                this.selectedTrainers.push(group.selectedObject);
            }
            this.$refs.autocomplete1.clear();
        },
        removeTrainer(trainer_id){
            let trainers = this.selectedTrainers.filter(d => d.id !== trainer_id);
            this.selectedTrainers = trainers;
        }
    }
  };
</script>


<style scoped>

</style>