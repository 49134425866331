<template>
  <div :key="componentKey">
    <CToaster :autohide="3000">
      <CToast
        :show="showSuccessToaster"
        color="success"
        header="Notification"
      >
        Success!
      </CToast>
    </CToaster>

    <a-drawer
      title="Mass Assign Course to Trainers"
      placement="right"
      :visible="courseTrainersDrawerVisible"
      @close="closeDrawer"
      :zIndex=99999
      :width=1200
    >
        <mass-course-trainer :key="massAssignKey" @sendToTrainers="receivedFromMassAssign"></mass-course-trainer>
    </a-drawer>


     <a-drawer
      title="Send Customer Trainer Notifications"
      placement="right"
      :visible="customNotificationDrawerVisible"
      @close="closeDrawer"
      :zIndex=99999
      :width=1200
    >
        <custom-trainer-notification :key="customNotificationKey" @sendToTrainers="receivedFromCustomTrainerNotification"></custom-trainer-notification>
    </a-drawer>
    
    <CCard>
        <CCardHeader>
            <slot name="header">
            <CIcon name="cil-people"/>  {{ $options.name }} 
            <div class="card-header-actions">
                <div class="row">
                    <a-switch @change="switchFilterChange" checked-children="Active Trainers" un-checked-children="All Trainers" :checked="isActiveTrainers"/>
                    <div class="col-auto">
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                <CIcon name="cil-options"/>
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item @click="openDrawer('massAssignCourseTrainer')">
                                    <a>Mass Assign Courses to Trainer</a>
                                </a-menu-item>
                                <a-menu-item @click="openDrawer('sendCustomTrainerNotification')">
                                    <a>Send Custom Trainer Notification</a>
                                </a-menu-item>                                
                            </a-menu>
                        </a-dropdown>
                    </div>
                </div>
            </div>
            </slot>
        </CCardHeader>
        <CCardBody>
            <VueTabulator ref="tabulator" :options="tabulatorOptions"/>
        </CCardBody>
    </CCard>
  </div>
</template>

<script>
  import encryptStorage from "../../controllers/encryptStorage.js";
  import { mapState } from "vuex";
  import _startCase from 'lodash/startCase';
  import MassCourseTrainer from "../../views/utils/MassCourseTrainer.vue";
  import CustomTrainerNotification from "../../views/utils/CustomTrainerNotification.vue";

  export default {
    name: "Trainers",
    components: { MassCourseTrainer, CustomTrainerNotification },
    computed: {
      ...mapState(["endPoint"])
    },
    mounted() {
      this.tabulatorInstance = this.$refs.tabulator.getInstance();
      if (this.isActiveTrainers) this.tabulatorInstance.addFilter("active", "=", true);
    },
    data() {
      return {
        showSuccessToaster: false,
        massAssignKey: 0,
        courseTrainersDrawerVisible: false,
        customNotificationKey: 0,
        customNotificationDrawerVisible: false,
        isActiveTrainers: true,
        tabulatorInstance: null,
        loggedInUserRole: encryptStorage.getItem('loggedInUserRole'),
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        componentKey: 0,
        tabulatorOptions: {
          placeholder:"Loading..",
          ajaxLoaderLoading:"<span>Loading Data</span>",
          ajaxURL: this.$store.state.endPoint+"/api/v1/users/get_all_trainers",
          ajaxConfig:{
                  method:"GET",
                  headers: {
                    "Authorization" : 'Bearer ' + encryptStorage.getItem("accessToken"),
                    "Access-Control-Allow-Origin": "*"
                  },
              },
          ajaxProgressiveLoad: "scroll",
          height:"70vh",
          layout:"fitData",
          rowClick: this.tabulatorRowClick,
          movableColumns: true,
          columns: [
            { field: 'full_name', title: 'Name' },
            { field: 'email', title: 'Email' },
            { field: 'tel', title: 'Tel' },
            { field: 'address', title: 'Address' },
            { field: 'active', title: 'Active', formatter: this.yesNoFormatter },
            { field: 'is_vaccinated', title: 'Vaccinated', formatter: this.yesNoFormatter },
            { field: 'medical_exemption', title: 'Medical Exempted', formatter: this.yesNoFormatter },
            { field: 'other_restrictions', title: 'Other Restrictions' },
            { field: 'knights_other_restrictions', title: 'Knights - Other Restrictions' },
          ]
        },
      };
    }, 
    methods: {
        receivedFromMassAssign(){
          this.closeDrawer();
          this.showSuccessToaster = true;
        },
        receivedFromCustomTrainerNotification(){
          this.closeDrawer();
          this.showSuccessToaster = true;
        },
        closeDrawer(){
          this.courseTrainersDrawerVisible = false;
          this.massAssignKey++;
          this.customNotificationDrawerVisible = false;
          this.customNotificationKey++;
        },
        openDrawer(type){
          if (type == 'massAssignCourseTrainer') {
            this.courseTrainersDrawerVisible = true;
            this.massAssignKey++;
          } else if (type == 'sendCustomTrainerNotification') {
            this.customNotificationDrawerVisible = true;
            this.customNotificationKey++;
          }

        },
        yesNoFormatter(cell){
            let value = cell.getValue();
            return (value) ? "Yes" : "No"

        },
        switchFilterChange(state) {
            this.isActiveTrainers = state;
            if (state==true) {
                this.tabulatorInstance.addFilter("active", "=", state);
            } else {
                this.tabulatorInstance.removeFilter("active", "=", true);
            }
        }
    }
  };
  
</script>
<style lang='scss'>
  @import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";
</style>
